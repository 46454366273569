import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChangePasswordPayload, LoginPayload, SignupPayload, StorageKeys, User, UserCreatePayload } from '../../models/user.model'
import { Collection } from '../../models/collection.model'


const PAGE = 1
const PER_PAGE = 40

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  setRemember(remember: boolean) {
    remember = true
    localStorage.setItem(StorageKeys.Remember, String(remember));
  }

  getRemember(): boolean {
    const value = localStorage.getItem(StorageKeys.Remember);
    return value == "true";
  }

  setCurrentUser(u: User) {
    const data = JSON.stringify(u);
    localStorage.setItem(StorageKeys.CurrentUser, data);
  }

  getCurrentUser(): User {
    let data = localStorage.getItem(StorageKeys.CurrentUser);
    if (data) {
      return JSON.parse(data);
    }

    return new User();
  }

  removeMeditLinkProfile(){
    let data = localStorage.getItem(StorageKeys.CurrentUser);
    if (data) {
      let user =  JSON.parse(data);
      user.meditLinkUserProfile = null
      user.meditLinkAuthCompleted = false
      const newUser = JSON.stringify(user);
      localStorage.setItem(StorageKeys.CurrentUser, newUser);
    }
  }

  isMeditlinkLogin(): boolean{
    let data = localStorage.getItem(StorageKeys.CurrentUser);
    if (!data) {
      return false
    } else {
      const user = JSON.parse(data)
      return user.meditLinkAuthCompleted || false
    }
  }

  getMeditlinkProfile(){
    
  }

  async getMe(){
    const r = await this.http.get(`/api/clinic/users/me`).toPromise();

    return r as User;
  }
  async getUsers(): Promise<User[]> {
    const r = await this.http.get(`/api/clinic/users`).toPromise();
    return r as User[];
  }

  async getUserById(userId: number): Promise<User> {
    const r = await this.http.get(`/api/clinic/users/${userId}`).toPromise();
    return new User(r);
  }

  async createUser(u: UserCreatePayload): Promise<User> {
    const r = await this.http.post(`/api/clinic/users`, u).toPromise();
    return new User(r);
  }

  async updateUser(u: User): Promise<User> {
    const payload = {
      name: u.name,
      clinicName: u.clinicName,
      address: u.address,
      website: u.website,
      phone: u.phone
    };
    const r = await this.http
      .patch(`/api/clinic/users/${u.id}`, payload)
      .toPromise();
    return new User(r);
  }
  async deleteUser(u: User): Promise<boolean> {
    const r = await this.http
      .delete(`/api/clinic/users/${u.id}`)
      .toPromise();
    return true;
  }

  async updatePassword(userId: number, payload: ChangePasswordPayload) {
    return this.http
      .put(`/api/clinic/users/${userId}/password`, payload)
      .toPromise();
  }

  uploadProfileImage(userID: number, file: File, size: any, type?: any) {
    const formData = new FormData();
    formData.append("image", file);
    if (type) {
      formData.append("type", type);
    }

    return this.http.post(`/api/clinic/users/${userID}/image`, formData);
  }

  async signup(payload: any) {
    await this.http.post(`/api/clinic/signup`, payload).toPromise();
  }

  async changeCustomerOwner(token: string, newPassword: string) {
    return this.http
      .post("/api/clinic/customer-owner-change", {
        token: token,
        newPassword: newPassword
      })
      .toPromise();
  }

  meditlinkComplete(code: string, state: string){
    return this.http
    .post("/api/oauth/medit-link/complete", {
      code: code,
      state: state
    })
    .toPromise();
  }

  async getSplagAdsUnRead(){
    return this.http.get('/api/splash-ads/unread?perPage=20&page=1').toPromise()
  }

  async readSplashAds(splashId: number){
    
    return this.http.post(`/api/splash-ads/${splashId}/read`, {}).toPromise()
  }
}
